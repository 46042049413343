import React, {useRef, useState} from 'react';
import type { FC, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box, Button, Collapse, ListItem, Popover, useMediaQuery} from '@material-ui/core';
import type { ListItemProps } from '@material-ui/core';
import ChevronDownIcon from '../res/icons/ChevronDown';
import ChevronRightIcon from '../res/icons/ChevronRight';
import i18n from "i18next";
import {useTheme} from "@material-ui/core/styles";

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
}

const NavItem: FC<NavItemProps> = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    ...other
  } = props;
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(openProp);
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {defaultMatches: true});

    const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0
        }}
        {...other}
      >
        <Button
          endIcon={!open ? <ChevronRightIcon fontSize="small" />
            : <ChevronDownIcon fontSize="small" />}
          onClick={handleToggle}
          startIcon={icon}
          ref={anchorRef}
          sx={{
            color: active ? 'text.secondary' : null,
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: '12px',
            textAlign: 'left',
            textTransform: 'none',
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
          {info}
        </Button>

          <Popover
              anchorEl={anchorRef.current}
              anchorOrigin={!isMobile ? ({
                  vertical: 'bottom',
                  horizontal: 'center',
              }) : ({ vertical: 'center',
                  horizontal: 'right'})}
              transformOrigin={!isMobile ?{
                  vertical: 'top',
                  horizontal: 'center',
              }:{
                  vertical: 'top',
                  horizontal: 'center',
              }}
              keepMounted
              onClose={handleToggle}
              open={open}
              PaperProps={{
              }}
          >
              <Box sx={{ pt: 2, pl: 2 , pr: 2, pb: 2}}>
                  {children}
              </Box>
          </Popover>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
    >
      <Button
        component={path && RouterLink}
        startIcon={icon}
        sx={{
            color: 'text.primary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'center',
            textAlign: 'center',
            pl: `${paddingLeft}px`,
            pr: '8px',
            textTransform: 'none',
            //per disattivare la trasparenza quando fai l'hover.
            "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
                color: 'secondary.main'
            },
        //width: '100%',
          ...(
            active && {
              color: 'secondary.main',
              fontWeight: 'fontWeightBold',
              '& svg': {
                color: 'secondary.main'
              }
            }
          )
        }}
        variant="text"
        to={path}
      >
        <Box sx={{ flexGrow: 1 }}>
          {title}
        </Box>
        {info}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  active: false,
  open: false
};

export default NavItem;
