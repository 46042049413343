import {APIError, MapApiError, NetworkError} from "../model/types/apiError";
import config from "../config";


// tslint:disable:max-line-length
export class ApiUtil {
    // @ts-ignore
    public static async makeRequest<T>(methodRequest: "post" | "put" | "get" | "delete", path: string, auth: boolean, body?: object, snack: boolean = true, customToken?: string, customUrl?: boolean): Promise<T> {
        const args: RequestInit = { method: methodRequest };
        if (methodRequest === "post" || methodRequest === "put") {
            args.body = JSON.stringify(body);
        }
        args.headers = new Headers({
            "Content-Type": "application/json",
        });
        if (auth) {
            const myToken = customToken ? customToken : localStorage.getItem("accessToken");
            args.headers.append("x-access-token", myToken);
        }
        const endpoint = config.API_ENDPOINT;
        let json: any;
        try {
           // appStore.showRequestLoading();
          //  console.log("REQUEST: ", {url: customUrl  ? path : endpoint + path, args});
            const fetchReq = await fetch(customUrl  ? path : endpoint + path, args);

            try {
                json = await fetchReq.json();
                console.log("JSONResponse", json);
            } catch (erResp) {
                console.log("fetchJsonError", erResp);
                json = JSON.parse("{}")!!;  // TODO HANDLE
            }
            if ([200, 201, 204, 304].includes(fetchReq.status)) {
              //  appStore.hideRequestLoading(fetchReq, snack);
                return json;
            } else {
                const err = MapApiError.get(fetchReq.status);
                if (err) {
                    throw MapApiError.get(fetchReq.status);
                }
                throw fetchReq;
            }
        } catch (err) {
      //      appStore.hideRequestLoading(err, snack);
            console.log("fetchCatchError", {err, snack});
            if(err !== undefined){
                if (err.message) {
                    if (err.message === "Network request failed") {
                        throw NetworkError;
                    }
                }
                if(json !== undefined){
                    err.stack = json
                }
                if (err as APIError) {
                    this.commonError(err.status, auth);
                    throw err;
                }
            }else{
                if(json){
                    let error:APIError =  json;
                    error.stack = json;
                    this.commonError(error.status, auth);
                    throw error
                }
            }
            throw err;
        }
    }
    public static async makeRequestFile<T>(methodRequest: "post" | "put" | "get" | "delete", path: string, auth: boolean, body?: object, snack: boolean = true, customToken?: string, customUrl?: boolean): Promise<Blob> {
        const args: RequestInit = { method: methodRequest };
        if (methodRequest === "post" || methodRequest === "put") {
            args.body = JSON.stringify(body);
        }
        args.headers = new Headers({
            "Content-Type": "application/json",
        });
        if (auth) {
            const myToken = customToken ? customToken : localStorage.getItem("accessToken");
            args.headers.append("x-access-token", myToken);
        }
        const endpoint = config.API_ENDPOINT;
        let json: any;
        try {
            // appStore.showRequestLoading();
            //  console.log("REQUEST: ", {url: customUrl  ? path : endpoint + path, args});
            const fetchReq = await fetch(customUrl  ? path : endpoint + path, args);
            if (fetchReq.ok) {
                return await fetchReq.blob();
            }else {
                const err = MapApiError.get(fetchReq.status);
                if (err) {
                    throw MapApiError.get(fetchReq.status);
                }
                throw fetchReq;
            }
        } catch (err) {
            //      appStore.hideRequestLoading(err, snack);
            console.log("fetchCatchError", {err, snack});
            if(err !== undefined){
                if (err.message) {
                    if (err.message === "Network request failed") {
                        throw NetworkError;
                    }
                }
                if(json !== undefined){
                    err.stack = json
                }
                if (err as APIError) {
                    this.commonError(err.status, auth);
                    throw err;
                }
            }else{
                if(json){
                    let error:APIError =  json;
                    error.stack = json;
                    this.commonError(error.status, auth);
                    throw error
                }
            }
            throw err;
        }
    }
    public static commonError(code: number, auth: boolean){
        if ( (code === 401) && auth ) {
            //TODO ChartController.doLogout();
        } else if (code === 400) {
            //TODO
        } else if (code === 500) {
            //TODO
        }
    }
    public static async downloadFile(){

    }
}
