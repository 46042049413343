import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import {DeviceUUID} from 'device-uuid'
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import useScrollReset from './hooks/useScrollReset';
import routes from './routes';
import { createCustomTheme } from './theme';
import useSettings from "./hooks/useSettings";
import {loadStripe} from "@stripe/stripe-js";
import config from "./config";
import {Elements} from '@stripe/react-stripe-js';
import useAuth from './hooks/useAuth';
import {AuthContextHelper} from "./contexts/JWTContext";
import SplashScreen from "./components/SplashScreen";

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const stripePromise = loadStripe(config.STRIPE_PUB_KEY_DEV);
  const auth = useAuth();
  AuthContextHelper.setAuthContext(auth)
  useScrollReset();

  useEffect(() => {}, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  if(!localStorage.getItem("uuid")){
    const du = new DeviceUUID().parse();
    const dua = [
      du.language, du.platform, du.os, du.cpuCores, du.isAuthoritative, du.silkAccelerated, du.isKindleFire, du.isDesktop, du.isMobile,
      du.isTablet, du.isWindows, du.isLinux, du.isLinux64, du.isMac, du.isiPad, du.isiPhone, du.isiPod, du.isSmartTV,
      du.pixelDepth, du.isTouchScreen];
    localStorage.setItem("uuid",du.hashMD5(dua.join(':')))
  }
  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
      <CssBaseline />
        <Toaster position="top-center" />
        {auth.isInitialized ? content : <SplashScreen />}
      </Elements>
    </ThemeProvider>
  );
};

export default App;
