const config = {
  ENV: process.env.NODE_ENV,
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  DEBUG: process.env.REACT_APP_DEBUG === "true" || false,
  DEFAULT_LANG: process.env.REACT_APP_DEFAULT_LANG || "it",
  STRIPE_PUB_KEY_DEV: process.env.REACT_APP_STRIPE_PUB_KEY_DEV,
  DOMAIN: process.env.REACT_APP_DOMAIN
};

export default config;

