import type {FC, ReactNode} from 'react';
import {useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from "../../hooks/useAuth";
import {Role} from "../../model/types/profilece";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const {user} = useAuth()
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated || !user || user.role !== Role.backoffice) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    //return to <Login />
    return <Navigate to={"/"}/>;
  }
  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
