import type { FC } from 'react';
import {
  Box,
  Container,
  Grid,
  Link, Stack,
  Typography
} from '@material-ui/core';
import LogoFooter from './LogoFooter';
import {LINK_COOKIE_POLICY, LINK_PRIVACY} from "../constants";
import React from "react";
import i18n from "i18next";

const sections = [
  {
    title: 'Privacy Policy',
    href: LINK_PRIVACY
  },
  {
    title: 'Cookie policy',
    href: LINK_COOKIE_POLICY
  },
  {
    title: 'Terms',
    href: '/tos'
  },
  {
    title: 'F.A.Q',
    href: '/faq'
  },
];

const Footer: FC = (props) => (
  <Box
    sx={{
      backgroundColor: '#edf0f4',
      p: 2
    }}
    {...props}
  >
    <Container maxWidth="lg">
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <LogoFooter />
          <Typography
              color="textSecondary"
              sx={{ mt: 2 }}
              variant="caption"
          >
            {i18n.t("copyright_footer")}
          </Typography>
        </Grid>
        <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
        >

          {
            /*
            <Stack direction={"row"} spacing={2}>
            <Link
                href={sections[0].href}
                color="textPrimary"
                variant="subtitle2"
            >
              {sections[0].title}
            </Link>
            <Link
                href={sections[1].href}
                color="textPrimary"
                variant="subtitle2"
            >
              {sections[1].title}
            </Link>
            <Link
                href={sections[2].href}
                color="textPrimary"
                variant="subtitle2"
            >
              {sections[2].title}
            </Link>
            <Link
                href={sections[3].href}
                color="textPrimary"
                variant="subtitle2"
            >
              {sections[3].title}
            </Link>
          </Stack>
             */
          }
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default Footer;
