import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import {User} from "../model/types/user";
import {UserController} from "../api/controller/user.controller";
import {AuthController} from "../api/controller/auth.controller";

interface State {
    isInitialized: boolean;
    isAuthenticated: boolean;
    user: User | null;
}

interface AuthContextValue extends State {
    platform: 'JWT';
    login: (username: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    logged: (user: User,token: string) => Promise<void>;
}

interface AuthProviderProps {
    children: ReactNode;
}

type InitializeAction = {
    type: 'INITIALIZE';
    payload: {
        isAuthenticated: boolean;
        user: User | null;
    };
};

type LoginAction = {
    type: 'LOGIN';
    payload: {
        user: User;
        token: string
    };
};

type LogoutAction = {
    type: 'LOGOUT';
};

type RegisterAction = {
    type: 'REGISTER';
    payload: {
        user: User;
    };
};

type Action =
    | InitializeAction
    | LoginAction
    | LogoutAction
    | RegisterAction;

const initialState: State = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const handlers: Record<string, (state: State, action: Action) => State> = {
    INITIALIZE: (state: State, action: InitializeAction): State => {
        const { isAuthenticated, user } = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        };
    },
    LOGIN: (state: State, action: LoginAction): State => {
        const { user } = action.payload;
        console.log("LOGIN ACTION")
        window.localStorage.setItem('accessToken', action.payload.token);

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    },
    LOGOUT: (state: State): State => ({
        ...state,
        isAuthenticated: false,
        user: null
    }),
    REGISTER: (state: State, action: RegisterAction): State => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    }
};

const reducer = (state: State, action: Action): State => (
    handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
    ...initialState,
    platform: 'JWT',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    logged: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');

                if (accessToken) {
                   const user =  (await UserController.profile())

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        };

        initialize();
    }, []);

    const login = async (username: string, password: string): Promise<void> => {
        const respLogin = await AuthController.sign(username, password);
        AuthContextHelper.setAuthContext(this)
        dispatch({
            type: 'LOGIN',
            payload: {
                user: respLogin.data.user,
                token: respLogin.data.token
            }
        });
    };

    const logged = async (user: User, token: string): Promise<void> => {
        console.log("logged",user,token)
        AuthContextHelper.setAuthContext(this)
        dispatch({
            type: 'LOGIN',
            payload: {
                user: user,
                token: token
            }
        });
    };
    const logout = async (): Promise<void> => {
        localStorage.removeItem('accessToken');
        dispatch({ type: 'LOGOUT' });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                platform: 'JWT',
                login,
                logout,
                logged
        }}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export class AuthContextHelper{
    private static authContext: AuthContextValue;

    public static getAuthContext(): AuthContextValue{
        return this.authContext
    }
    public static setAuthContext(authContext: AuthContextValue){
        this.authContext = authContext
    }
}

export default AuthContext;
