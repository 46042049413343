import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
        "appName":"Catasto Energetico",
        "termini_condizioni": "Termini e condizioni",
        "conferma": "Conferma",
        "salva": "Salva",
        "faq": "F.A.Q",
        "subTitleFaq": "Qui puoi trovare le risposte alle domande più frequenti.",
        "torna_indietro": "Torna indietro",
        "401_subtitle": "Stai provando ad accedere ad una pagina che richiede l'autenticazione, prosegui la navigazione usando la barra in alto o torna indietro.",
        "401_subtitle_admin": "Non hai i permessi sufficienti per visualizzare questa pagina.",
        "401_title": "Errore 401 non sei autorizzato",
        "erroreServer": "Errore server",
        "500_title": "Errore 500 problema interno del server",
        "500_subtitle": "La richiesta che hai inviato, non è andata a buon fine, riprova o contatta l'assistenza del servizio tecnico, descrivendo il problema.",
        "non_trovato": "Non trovato",
        "404_title": "Errore 404 risorsa cercata non trovata",
        "404_subtitle": "Il percorso ricerca non è stato trovato, controlla di aver digitato correttamente il link, riprova.",
        "logout": "Logout",
        "vai": "Vai",
        "overview":"Home",
        "disclaimer":"Disclaimer",
        "required_iban":"Devi compilare il campo con l'iban",
        "required_invoiceidentifier":"Devi compilare il campo relativo al codice destinario/pec per l'invio della fattura",
        "profilo": "Profilo",
        "iban":"Iban",
        "cellulare":"Cellulare",
        "name":"Nome",
        "cognome":"Cognome",
        "data_nascita":"Data di nascita",
        "prosegui":"Prosegui",
        "onboarding_success":"On boarding completato con scucesso",
        "required_datanascita":"Il campo data di nascita è richiesta.",
        "dati_catasto_energetico":"Dati edificio",
        "dati_catasto_energetico_informazioni_base":"Informazioni base",
        "informazioni_aggiuntive":"Informazioni aggiuntive",
        "required_valid_iban":"E' richiesto un iban valido",
        "role_is_wrong":"Il ruolo dell'utente è sbagliato.",
        "errore_500":"Errore 500",
        "no_data_provided":"Non sono state fornite le informazioni necessarie.",
        "lista_degli_edifici":"Lista degli edifici",
        "code_not_valid":"Codice non valido",
        "verifica_dati":"Verifica dati",
        "secret_code":"Secret code",
        "username":"Username",
        "codice_generato_inviato_email":"Codice generato ed inviato via email",
        "inserimento_codice":"Inserimento codice di verifica",
        "website":"Website",
        "password":"Password",
        "my_home":"My Home",
        "home":"Home",
        "le_tue_comunita":"Le tue comunità",
        "crea_nuova_comunita":"Crea nuova comunità",
        "accedi":"Accedi",
        "password_dimenticata":"Password dimenticata",
        'subtitleLogin': "Utilizza le tue credenziali per accedere alla piattaforma",
        "edifici_amministrati":"Edifici amministrati",
        "gestioni_sottoscrizioni":"Gestioni sottoscrizioni",
        "righe_per_pagine": "Righe per pagina",
        "codice_fiscale_indirizzo_search":"Inserisci il codice fiscale o indirizzo",
        "filtri_di_ricerca":"Filtri di ricerca",
        "ordina_per":"Ordina per",
        "gli_edifici_che_amministri":"GLI EDIFICI CHE AMMINISTRI",
        "in_regola":"In regola",
        "non_in_regola":"Non in regola",
        "onboarding":"Onboarding",
        "province":"Province",
        "city":"City",
        "numberApartaments":"Numero appartamenti",
        "sezioneUrbana":"Sezione urbana",
        "foglio":"Foglio",
        "cap":"Cap",
        "address":"Indirizzo",
        "addressNumber":"Numero",
        "region":"Regione",
        "particella":"Particella",
        "required_username":"Lo username è obbligatorio",
        "required_password":"La password è obbligatoria",
        "error_in_creating":"Errore in creazione",
        "incompleto":"Incompleto",
        "attiva_abbonamento":"Attiva abbonamento",
        "sospendi_abbonamento":"Sospendi abbonamento",
        "abbonamento_non_attivato":"Abbonamento non attivato, riprova nei prossimi giorni o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "abbonamento_non_sospeso":"Abbonamento non sospeso, riprova nei prossimi giorni o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "abbonamento_attivato":"Abbonamento attivato con successo!",
        "abbonamento_sospeso_success":"Abbonamento sospeso con successo!",
        "ri_attiva_abbonamento":"Riattiva l'abbonamento",
        "loading":"Caricamento in corso ...",
        "aggiungi_edificio":"Aggiungi edificio",
        "annulla":"Annulla",
        "continua":"Continua",
        "scegli_gli_edifici":"Scegli gli edifici",
        "communityName":"Nome comunità",
        "scegli_i_tuoi_edifici":"Scegli i tuoi edifici",
        "esamina_i_risultati":"Esamina i risultati",
        "coinvolgi_condomini":"Coinvolgi i condòmini",
        "inizia_analisi_preliminare":"e inizia l'analisi preliminare",
        "coinvolgi_condomini_inizia_analisi":"Coinvolgi i condòmini e inizia l'analisi preliminare",
        "compila_i_dati":"Compila i dati",
        "delibera_assemblea_carica_doc":"Delibera in assemblea e carica i documenti",
        "procedi_costituzione":"Procedi con la costituzione",
        "dai_un_nome_comunita":"Dai un nome alla tua comunità",
        "required_secret_code":"Il codice segreto è necessario",
        "edifici_di_cui_sei_terzo_responsabile":"Edifici di cui sei Terzo Responsabile",
        "stato_abbonamenti":"Stato abbonamenti",
        "edifici_possibile_sottoscrizione":"EDIFICI PER I QUALI È POSSIBILE EFFETTUARE LA SOTTOSCRIZIONE",
        "edifici_gia_sottoscritti":"EDIFICI GIÀ SOTTOSCRITTI",
        "completa_pagamento":"Completa pagamento",
        "non_ci_sono_abbonamenti_attivi":"Non ci sono abbonamenti attivi",
        "edifici_associati_impianti_terzo_respos":"EDIFICI ASSOCIATI AGLI IMPIANTI DI CUI SEI TERZO RESPONSABILE",
        "seleziona_x_il_pagamento":"Seleziona per il pagamento",
        "le_mie_comunita":"Le mie comunità",
        "tutti_gli_edifici_abbonati":"Tutti gli edifici sono abbonati",
        "provincia":"Provincia",
        "regione":"Regione",
        "edifici_selezionati":"Edifici selezionati",
        "nessun_edificio_selezionato":"Nessun edificio selezionato",
        "torna_a_selezione_edifici":"Torna a selezione edifici",
        "avanti":"Avanti",
        "error_creation_community":"Errore nella creazione della comunità",
        "community_creata":"Comunità creata con successo.",
        "required_name_community":"Il campo nome community è obbligatorio",
        "all":"Tutte",
        "all_m":"Tutti",
        "luci":"Luci",
        "centrale_termica":"Centrale termica",
        "ascensore":"Ascensore",
        "altro":"Altro",
        "si":"Si",
        "no":"No",
        "vecchia_generazione":"É di vecchia generazione",
        "basso_consumo":"É a basso consumo",
        "in_parte_cond_privt":"In parte condominiale in parte privata",
        "riscaldamento_centralizzato":"Riscaldamento centralizzato",
        "riscaldamento_centralizzato_ed_acqaua":"Riscaldamento e acqua calda sanitaria centralizzati",
        "quale_anno_generatore_termico":"Quale è l'anno del generatore termico?",
        "i_tuoi_edifici":"I TUOI EDIFICI",
        "condominiale":"Condominiale",
        "privata":"Privata",
        "salvataggio_non_riuscito":"Salvataggio non riuscito",
        "informazioni_mancanti":"Informazioni mancanti",
        "avvia_analisi":"Avvia analisi",
        "data_di_scadenza":"Data di scadenza",
        "error_obtaining_communities_list":"Errore nell'ottenimento della lista delle community",
        "ricerca_x_nome":"Ricerca per nome",
        "error_creating_survey":"Errore nella creazione del sondaggio",
        "indietro":"Indietro",
        "in_attesa_delle_risposte_condomini":"In attesa delle risposte dei condomini...",
        "link_raccolta_dati":"Link raccolta dati",
        "termina_ora":"Termina ora",
        "risultati":"Risultati",
        "risposte_totali_ottenute":"Risposte totali ottenute",
        "edifici_coinvolti":"Edifici coinvolti",
        "risposte":"Risposte",
        "surveyStartTitle":"Compila il sondaggio",
        "surveyStopTitle":"Il sondaggio è terminato",
        "not_found":"Non trovato",
        "community_non_trovata":"Community non trovata.",
        "edificio_gia_in_altre_comunita": "Edificio già compreso in altra comunità",
        "edificio_gia_in_altre_comunita_ipotesi":"Edificio già compreso in altra ipotesi di comunità",
        "distanza_eccessiva":"Distanza eccessiva",
        "completa_il_sondaggio":"Completa il sondaggio",
        "edificio_selezionato":"Edificio selezionato",
        "other":"Altro",
        "orario":"Orario",
        "spezzato":"Spezzato",
        "ora_inizio":"Ora inizio",
        "ora_fine":"Ora fine",
        "aperto24h":"Aperto 24h",
        "chiuso":"Chiuso",
        "pagamenti":"Pagamenti",
        "gestione_comunita":"Gestione comunità",
        "comunita_in_sviluppo":"Comunità in sviluppo",
        "inserisci_nome_comunita":"Inserisci nome comunità",
        "ultima_modifica":"Ultima modifica",
        "stato":"Stato",
        "sconosciuto":"Sconosciuto",
        "attendi_sondaggio":"Attendi sondaggio",
        "inizia_analisi":"Inizia analisi",
        "scegli_edifici":"Scegli edifici",
        "edifici_catasto_energetico":"Gli edifici di catasto energetico",
        "comunita_energo":"Tutte le comunità",
        "in_sviluppo":"In sviluppo",
        "la_data_non_puo_essere_antecedente":"La data non può essere antecedente",
        "salvataggio_riuscito":"Salvataggio riuscito",
        "esamina_risultati_e_procedi":"Esamina i risultati e procedi",
        "riepilogo":"Riepilogo",
        "comunita_energetica":"Comunità energetica",
        "impianti_fotovoltaici":"Impianti fotovoltaici",
        "compensi_sviluppatore":"Compensi sviluppatore",
        "dettaglio":"Dettaglio",
        "aggiungi_edifici":"Aggiungi edifici",
        "successivo":"Successivo",
        "problemi_onboarding_owner":"Problemi con la registrazione dell'amministratore",
        "required_valid_website":"Inserisci un indirizzo web valido",
        "no_community":"Non ci sono comunità",
        "title_onboarding":"Abbiamo integrato nuove funzionalità",
        "subtitle_onboarding":"D'ora in avanti potrai gestire i pagamenti direttamente in app e accedere a nuove funzionalità come lo sviluppo di comunità energetiche. Sono però necessari alcune informazioni aggiuntive",
        "per_modificare_torna_catasto":"Per modificare questo campo torna a Modifica dati personali dalla home",
        "problemi_nel_caricamento":"Problemi nel caricamento",
        "ditta":"Ditta",
        "nominativo":"Nominativo",
        "gestione_sottoscrizioni":"Gestione sottoscrizioni",
        "dati_edificio":"Dati edificio",
        "verifica_lo_stato_degli_abbonamenti_a_catasto":"Verifica lo stato degli abbonamenti a Catasto Energetico",
        "verifica_stato_edifici_amministrati_contractor":"Verifica lo stato degli abbonamenti a Catasto Energetico di ciascun edificio di cui sei Terzo responsabile/Manutentore",
        "subtitle_onboarding_building":"Rivedi le informazioni associate all'edificio ed inserisci l'IBAN del condominio sul quale verrà addebitato l'abbonamento a Catasto Energetico",
        "inserisci_iban_condominio":"Inserisci iban condominio",
        "sottoscrizione_effettuata_con_successo":"Sottoscrizione effettuata con successo",
        "sviluppo_gestione_comunita":"Sviluppo gestione comunità",
        "iban_condominio":"Iban condominio",
        "modifica_iban":"Modifica iban",
        "message_cc_disabled":"Verifica di aver inserito una carta di credito valida.",
        "metodo_di_pagamento_aggiornato":"Metodo di pagamento aggiornato.",
        "i_tuoi_dati_di_pagamento":"Dati di pagamento",
        "dati":"Dati",
        "subtitle_i_tuoi_dati_di_pagamento":"In questa sezione puoi inserire il tuo IBAN personale. Sarà utilizzato solo per accrediti futuri nei tuoi confronti",
        "subtitle_i_tuoi_dati_di_pagamento_manutentore":"In questa sezione è possibile modificare i tuoi dati di pagamento",
        "dati_di_pagamento":"Dati di pagamento",
        "modifica_dati_carta_di_credito":"Modifica dati carta di credito",
        "last4":"Ultimi 4 numeri della carta",
        "le_comunita_in_sviluppo":"Le comunità in sviluppo",
        "da_inserire_dati_coperture":"Da inserire dati coperture",
        "gestisci":"Gestisci",
        "le_comunita_in_gestione":"Le comunità in gestione",
        "in_gestione":"In gestione",
        "compila_dati":"Compila dati",
        "inserire_dati_coperture":"Inserire dati coperture",
        "link_sondaggio":"Link sondaggio",
        "scandeza_link_sondaggio":"Scandeza link sondaggio",
        "data_creazione":"Data di creazione",
        "email":"Email",
        "amministratore":"Amministratore",
        "telefono":"Telefono",
        "torna_a_gestione_comunita":"Torna a gestione comunità",
        "edifici_inseriti":"Edifici inseriti",
        "note":"Note",
        "aree_individuate":"Aree individuate",
        "aggiungi_area":"Aggiungi area",
        "area":"Area",
        "lunghezza":"Lunghezza",
        "larghezza":"Larghezza",
        "azimut":"Azimut",
        "benvenuto":"Benvenuto",
        "comunita_energetiche":"Comunità energetiche",
        "delibera_in_assemblea_carica_doc":"Delibera in assemblea e carica i documenti richiesti",
        "condomini_coperture_interessate":"Condomini le cui coperture sono interessate",
        "nuovi_impianti":"Nuovi impianti",
        "numero_impianti":"Numero impianti",
        "investimento_stimato":"Investimento stimato",
        "scarica_materiale_informativo_assemblea":"Scarica materiale informativo da portare in assemblea",
        "scarica_incarico_progettazione_esecutiva":"Scarica incarico progettazione esecutiva",
        "carica_incarico_progettazione_esecutiva_firmato":"Carica incarico progettazione esecutiva firmato",
        "carica_delibera_incarico":"Carica delibera incarico",
        "scegli_file":"Scegli file",
        "procedi_con_la_costituzione":"Procedi con la costituzione",
        "documenti_da_scaricare":"Documenti da scaricare",
        "carica_i_seguenti_documenti_firmati":"Carica i seguenti documenti firmati",
        "carica_documenti":"Carica documenti",
        "carica_statuto_firmato":"Carica statuto firmato",
        "scarica_statuto_comunita":"Scarica Statuto comunità",
        "residenziale":"Residenziale",
        "ufficio":"Ufficio",
        "altre_attivita":"Negozio/Artigiano/Ristorante",
        "dati_identificativi":"Dati identificativi",
        "saving_survey_ok":"Sondaggio inviato con successo",
        "error_saving_survey":"Errore nel salvataggio del sondaggio",
        "sondaggio_non_trovato":"Sondaggio non trovato o scaduto",
        "sondaggio_completato":"Hai finito! Grazie per aver compilato il sondaggio.",
        "amministratore_manutentore_nome":"Nome dell'amminstratore o manutentore",
        "not_available":"Non disponibile",
        "risultati_non_ancora_disponibili":"Risultati non ancora disponibili",
        "non_ci_sono_edifici":"Non ci sono edifici",
        "seleziona_tutti":"Seleziona tutti",
        "edifici_considerati":"Edifici considerati",
        "importo_iva_22inclusa":"Importo (iva 22% inclusa):",
        "edificio": "Edificio",
        "riepilogo_edifici_ddot":"Riepilogo edifici:",
        "riepilogo_edifici":"Riepilogo edifici",
        "pagamento_e_durata":"Pagamento e durata",
        "carta_addebito_ddot":"Carta di addebito:",
        "durata_12_mesi_ddot":"Durata 12 mesi:",
        "iban_addebito_ddot":"IBAN addebito: ",
        "periodo_addebito_12_mesi":"Periodo di addebito 12 mesi:",
        "validita_12_mesi":"Validità 12 mesi:",
        "modifica_coperture":"Modifica coperture",
        "elimina":"Elimina",
        "rigenera_risultati":"Rigenera risultati",
        "e_stata_avviata_la_generazione_dei_risultati":"E' stata avviata con successo la rigenerazione dei risultati",
        "problemi_avvio_rigenerazione":"Problemi nella richiesta di rigenerazione dei risultati",
        "error_cancel_community":"Errore nella cancellazione della comunità",
        "community_cancellata_con_successo":"Community cancellata con successo",
        "sei_sicuro_di_voler_cancellare_la_comunita":"Sei sicuro di voler cancella la comunità: ",
        "sei_sicuro_di_voler_sospendere_abbonamento_edificio":"Sei sicuro di voler sospendere l'abbonamento per l'edificio:",
        "community_aggiornata":"Comunità aggiornata con successo",
        "error_update_community":"Errore aggiornamento comunità",
        "scarica_json_risultati":"Scarica json risultati",
        "nome":"Nome",
        "stato_attuale":"Stato attuale",
        "privacy":"Privacy",
        "coperture":"Coperture",
        "sondaggio":"Sondaggio",
        "turno_unico":"Turno unico",
        "onboarding_problem_creation_user":"Problemi nella creazione dell'utente, contatta il supporto",
        "payment_method_impostato":"Metodo di pagamento non impostato, non puoi procedere",
        "inserisci_la_carta_di_credito":"Inserisci la carta di credito",
        "pagamento_non_riuscito_contatta_assistenza":"Pagamento non riuscito: riprova nei prossimi giorni o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "error_aggiornamento_metodo_di_pagamento":"Problemi nell'aggiornamento del pagamento, riprova o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "inserisci_identificativo_fatture":"Inserisci il codice identificativo per l'invio delle fatture(SDI o pec)",
        "identificativo_fatture":"Identificativo delle fatture(SDI o PEC)",
        "modifica_dati":"Modifica dati",
        "inserisci_dati_cc_contractor":"Inserisci i dati della carta di credito/debito con cui potrai effettuare pagamenti per gli edifici",
        "dati_profilo":"Profilo",
        "i_tuoi_dati_profilo":"I tuoi dati del profilo",
        "modifica":"Modifica",
        "error_updating_profile":"Errore nell'aggiornamento del profilo",
        "problemi_metodo_di_pagamento_edificio":"Il tuo edificio risulta già registrato, ma non riusciamo a recuperare il metodo di pagamento precedentemente impostato, contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "inserimento_dati_coperture":"Inserimento dati coperture",
        "non_risultano_edifici_coperture":"Non risultano edifici che necessitano di inserimento delle coperture, verifica che siano stati compilati i dati",
        "in_attesa_di_conferma":"In attesa di conferma",
        "offerta_da":"Offerto da:",
        "indirizzo_edificio":"Indirizzo edificio:",
        "emailSoggettoTerzo":"Email del soggetto terzo",
        "nomeSoggettoTerzo":"Nome del soggetto terzo",
        "dati_importazione_massiva":"Dati per importazione massiva edifici/amministratori",
        "dati_importazione_massiva_subtitle":"In questa sezione potrai caricare un file csv che importerà in automatico gli edifici e gli amministratori, associandoli se necessario",
        "requiredNomeSoggettoTerzo":"Il nome del soggetto terzo è obbligatorio",
        "requiredEmailSoggettoTerzo":"La mail del soggetto terzo è obbligatoria",
        "required_csv":"Il csv è obbligatorio, verifica di averlo caricato correttamente o riprova",
        "importazione_completata":"Importazione completata.",
        "tipo_di_importazione":"Tipo di importazione",
        "simple":"Semplificata",
        "terzo":"Terzo",
        "scarica_qrcode":"Scarica Qr-Code",
        "copyright_footer":"© 2011-2024 Catasto Energetico. All Rights Reserved.",
        "vedi_risposte":"Vedi chi ha risposto"
    }
  },
  it: {
    translation: {
        "appName":"Catasto Energetico",
        "termini_condizioni": "Termini e condizioni",
        "conferma": "Conferma",
        "salva": "Salva",
        "faq": "F.A.Q",
        "subTitleFaq": "Qui puoi trovare le risposte alle domande più frequenti.",
        "torna_indietro": "Torna indietro",
        "401_subtitle": "Stai provando ad accedere ad una pagina che richiede l'autenticazione, prosegui la navigazione usando la barra in alto o torna indietro.",
        "401_subtitle_admin": "Non hai i permessi sufficienti per visualizzare questa pagina.",
        "401_title": "Errore 401 non sei autorizzato",
        "erroreServer": "Errore server",
        "500_title": "Errore 500 problema interno del server",
        "500_subtitle": "La richiesta che hai inviato, non è andata a buon fine, riprova o contatta l'assistenza del servizio tecnico, descrivendo il problema.",
        "non_trovato": "Non trovato",
        "404_title": "Errore 404 risorsa cercata non trovata",
        "404_subtitle": "Il percorso ricerca non è stato trovato, controlla di aver digitato correttamente il link, riprova.",
        "logout": "Logout",
        "vai": "Vai",
        "overview":"Home",
        "disclaimer":"Disclaimer",
        "required_iban":"Devi compilare il campo con l'iban",
        "required_invoiceidentifier":"Devi compilare il campo relativo al codice destinario/pec per l'invio della fattura",
        "profilo": "Profilo",
        "iban":"Iban",
        "cellulare":"Cellulare",
        "name":"Nome",
        "cognome":"Cognome",
        "data_nascita":"Data di nascita",
        "prosegui":"Prosegui",
        "onboarding_success":"On boarding completato con scucesso",
        "required_datanascita":"Il campo data di nascita è richiesta.",
        "dati_catasto_energetico":"Dati edificio",
        "dati_catasto_energetico_informazioni_base":"Informazioni base",
        "informazioni_aggiuntive":"Informazioni aggiuntive",
        "required_valid_iban":"E' richiesto un iban valido",
        "role_is_wrong":"Il ruolo dell'utente è sbagliato.",
        "errore_500":"Errore 500",
        "no_data_provided":"Non sono state fornite le informazioni necessarie.",
        "lista_degli_edifici":"Lista degli edifici",
        "code_not_valid":"Codice non valido",
        "verifica_dati":"Verifica dati",
        "secret_code":"Secret code",
        "username":"Username",
        "codice_generato_inviato_email":"Codice generato ed inviato via email",
        "inserimento_codice":"Inserimento codice di verifica",
        "website":"Website",
        "password":"Password",
        "my_home":"My Home",
        "home":"Home",
        "le_tue_comunita":"Le tue comunità",
        "crea_nuova_comunita":"Crea nuova comunità",
        "accedi":"Accedi",
        "password_dimenticata":"Password dimenticata",
        "subtitleLogin": "Utilizza le tue credenziali per accedere alla piattaforma",
        "edifici_amministrati":"Edifici amministrati",
        "gestioni_sottoscrizioni":"Gestioni sottoscrizioni",
        "righe_per_pagine": "Righe per pagina",
        "codice_fiscale_indirizzo_search":"Inserisci il codice fiscale o indirizzo",
        "filtri_di_ricerca":"Filtri di ricerca",
        "ordina_per":"Ordina per",
        "gli_edifici_che_amministri":"GLI EDIFICI CHE AMMINISTRI",
        "in_regola":"In regola",
        "non_in_regola":"Non in regola",
        "onboarding":"Onboarding",
        "province":"Province",
        "city":"City",
        "numberApartaments":"Numero appartamenti",
        "sezioneUrbana":"Sezione urbana",
        "foglio":"Foglio",
        "cap":"Cap",
        "address":"Indirizzo",
        "addressNumber":"Numero",
        "region":"Regione",
        "particella":"Particella",
        "required_username":"Lo username è obbligatorio",
        "required_password":"La password è obbligatoria",
        "error_in_creating":"Errore in creazione",
        "incompleto":"Incompleto",
        "attiva_abbonamento":"Attiva abbonamento",
        "sospendi_abbonamento":"Sospendi abbonamento",
        "abbonamento_non_attivato":"Abbonamento non attivato, riprova nei prossimi giorni o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "abbonamento_non_sospeso":"Abbonamento non sospeso, riprova nei prossimi giorni o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "abbonamento_attivato":"Abbonamento attivato con successo!",
        "abbonamento_sospeso_success":"Abbonamento sospeso con successo!",
        "ri_attiva_abbonamento":"Riattiva l'abbonamento",
        "loading":"Caricamento in corso ...",
        "aggiungi_edificio":"Aggiungi edificio",
        "annulla":"Annulla",
        "continua":"Continua",
        "scegli_gli_edifici":"Scegli gli edifici",
        "communityName":"Nome comunità",
        "scegli_i_tuoi_edifici":"Scegli i tuoi edifici",
        "esamina_i_risultati":"Esamina i risultati",
        "coinvolgi_condomini":"Coinvolgi i condòmini",
        "inizia_analisi_preliminare":"e inizia l'analisi preliminare",
        "coinvolgi_condomini_inizia_analisi":"Coinvolgi i condòmini e inizia l'analisi preliminare",
        "compila_i_dati":"Compila i dati",
        "delibera_assemblea_carica_doc":"Delibera in assemblea e carica i documenti",
        "procedi_costituzione":"Procedi con la costituzione",
        "dai_un_nome_comunita":"Dai un nome alla tua comunità",
        "required_secret_code":"Il codice segreto è necessario",
        "edifici_di_cui_sei_terzo_responsabile":"Edifici di cui sei Terzo Responsabile",
        "stato_abbonamenti":"Stato abbonamenti",
        "edifici_possibile_sottoscrizione":"EDIFICI PER I QUALI È POSSIBILE EFFETTUARE LA SOTTOSCRIZIONE",
        "edifici_gia_sottoscritti":"EDIFICI GIÀ SOTTOSCRITTI",
        "completa_pagamento":"Completa pagamento",
        "non_ci_sono_abbonamenti_attivi":"Non ci sono abbonamenti attivi",
        "edifici_associati_impianti_terzo_respos":"EDIFICI ASSOCIATI AGLI IMPIANTI DI CUI SEI TERZO RESPONSABILE",
        "seleziona_x_il_pagamento":"Seleziona per il pagamento",
        "le_mie_comunita":"Le mie comunità",
        "tutti_gli_edifici_abbonati":"Tutti gli edifici sono abbonati",
        "provincia":"Provincia",
        "regione":"Regione",
        "edifici_selezionati":"Edifici selezionati",
        "nessun_edificio_selezionato":"Nessun edificio selezionato",
        "torna_a_selezione_edifici":"Torna a selezione edifici",
        "avanti":"Avanti",
        "error_creation_community":"Errore nella creazione della comunità",
        "community_creata":"Comunità creata con successo.",
        "required_name_community":"Il campo nome community è obbligatorio",
        "all":"Tutte",
        "all_m":"Tutti",
        "luci":"Luci",
        "centrale_termica":"Centrale termica",
        "ascensore":"Ascensore",
        "altro":"Altro",
        "si":"Si",
        "no":"No",
        "vecchia_generazione":"É di vecchia generazione",
        "basso_consumo":"É a basso consumo",
        "in_parte_cond_privt":"In parte condominiale in parte privata",
        "riscaldamento_centralizzato":"Riscaldamento centralizzato",
        "riscaldamento_centralizzato_ed_acqaua":"Riscaldamento e acqua calda sanitaria centralizzati",
        "quale_anno_generatore_termico":"Quale è l'anno del generatore termico?",
        "i_tuoi_edifici":"I TUOI EDIFICI",
        "condominiale":"Condominiale",
        "privata":"Privata",
        "salvataggio_non_riuscito":"Salvataggio non riuscito",
        "informazioni_mancanti":"Informazioni mancanti",
        "avvia_analisi":"Avvia analisi",
        "data_di_scadenza":"Data di scadenza",
        "error_obtaining_communities_list":"Errore nell'ottenimento della lista delle community",
        "ricerca_x_nome":"Ricerca per nome",
        "error_creating_survey":"Errore nella creazione del sondaggio",
        "indietro":"Indietro",
        "in_attesa_delle_risposte_condomini":"In attesa delle risposte dei condòmini...",
        "link_raccolta_dati":"Link raccolta dati",
        "termina_ora":"Termina ora",
        "risultati":"Risultati",
        "risposte_totali_ottenute":"Risposte totali ottenute",
        "edifici_coinvolti":"Edifici coinvolti",
        "risposte":"Risposte",
        "surveyStartTitle":"Compila il sondaggio",
        "surveyStopTitle":"Il sondaggio è terminato",
        "not_found":"Non trovato",
        "community_non_trovata":"Community non trovata.",
        "edificio_gia_in_altre_comunita": "Edificio già compreso in altra comunità",
        "edificio_gia_in_altre_comunita_ipotesi":"Edificio già compreso in altra ipotesi di comunità",
        "distanza_eccessiva":"Distanza eccessiva",
        "completa_il_sondaggio":"Completa il sondaggio",
        "edificio_selezionato":"Edificio selezionato",
        "other":"Altro",
        "orario":"Orario",
        "spezzato":"Spezzato",
        "ora_inizio":"Ora inizio",
        "ora_fine":"Ora fine",
        "aperto24h":"Aperto 24h",
        "chiuso":"Chiuso",
        "orario_inizio_fine":"Orario inizio - Ora fine",
        "pagamenti":"Pagamenti",
        "gestione_comunita":"Gestione comunità",
        "comunita_in_sviluppo":"Comunità in sviluppo",
        "inserisci_nome_comunita":"Inserisci nome comunità",
        "ultima_modifica":"Ultima modifica",
        "stato":"Stato",
        "sconosciuto":"Sconosciuto",
        "attendi_sondaggio":"Attendi sondaggio",
        "inizia_analisi":"Inizia analisi",
        "scegli_edifici":"Scegli edifici",
        "edifici_catasto_energetico":"Gli edifici di catasto energetico",
        "comunita_energo":"Tutte le comunità",
        "in_sviluppo":"In sviluppo",
        "la_data_non_puo_essere_antecedente":"La data non può essere antecedente",
        "salvataggio_riuscito":"Salvataggio riuscito",
        "esamina_risultati_e_procedi":"Esamina i risultati e procedi",
        "riepilogo":"Riepilogo",
        "comunita_energetica":"Comunità energetica",
        "impianti_fotovoltaici":"Impianti fotovoltaici",
        "compensi_sviluppatore":"Compensi sviluppatore",
        "dettaglio":"Dettaglio",
        "aggiungi_edifici":"Aggiungi edifici",
        "successivo":"Successivo",
        "problemi_onboarding_owner":"Problemi con la registrazione dell'amministratore",
        "required_valid_website":"Inserisci un indirizzo web valido",
        "no_community":"Non ci sono comunità",
        "title_onboarding":"Abbiamo integrato nuove funzionalità",
        "subtitle_onboarding":"D'ora in avanti potrai gestire i pagamenti direttamente in app e accedere a nuove funzionalità come lo sviluppo di comunità energetiche. Sono però necessari alcune informazioni aggiuntive",
        "per_modificare_torna_catasto":"Per modificare questi campi torna a Modifica dati personali dalla home",
        "problemi_nel_caricamento":"Problemi nel caricamento",
        "ditta":"Ditta",
        "nominativo":"Nominativo",
        "gestione_sottoscrizioni":"Gestione sottoscrizioni",
        "dati_edificio":"Dati edificio",
        "verifica_lo_stato_degli_abbonamenti_a_catasto":"Verifica lo stato degli abbonamenti a Catasto Energetico",
        "verifica_stato_edifici_amministrati_contractor":"Verifica lo stato degli abbonamenti a Catasto Energetico di ciascun edificio di cui sei Terzo responsabile/Manutentore",
        "subtitle_onboarding_building":"Rivedi le informazioni associate all'edificio ed inserisci l'IBAN del condominio sul quale verrà addebitato l'abbonamento a Catasto Energetico",
        "inserisci_iban_condominio":"Inserisci iban condominio",
        "sottoscrizione_effettuata_con_successo":"Sottoscrizione effettuata con successo",
        "sviluppo_gestione_comunita":"Sviluppo gestione comunità",
        "iban_condominio":"Iban condominio",
        "modifica_iban":"Modifica iban",
        "message_cc_disabled":"Verifica di aver inserito una carta di credito valida.",
        "metodo_di_pagamento_aggiornato":"Metodo di pagamento aggiornato.",
        "i_tuoi_dati_di_pagamento":"Dati di pagamento",
        "dati":"Dati",
        "subtitle_i_tuoi_dati_di_pagamento":"In questa sezione puoi inserire il tuo IBAN personale. Sarà utilizzato solo per accrediti futuri nei tuoi confronti",
        "subtitle_i_tuoi_dati_di_pagamento_manutentore":"In questa sezione è possibile modificare i tuoi dati di pagamento",
        "dati_di_pagamento":"Dati di pagamento",
        "modifica_dati_carta_di_credito":"Modifica dati carta di credito",
        "last4":"Ultimi 4 numeri della carta",
        "le_comunita_in_sviluppo":"Le comunità in sviluppo",
        "da_inserire_dati_coperture":"Da inserire dati coperture",
        "gestisci":"Gestisci",
        "le_comunita_in_gestione":"Le comunità in gestione",
        "in_gestione":"In gestione",
        "compila_dati":"Compila dati",
        "inserire_dati_coperture":"Inserire dati coperture",
        "link_sondaggio":"Link sondaggio",
        "scandeza_link_sondaggio":"Scandeza link sondaggio",
        "data_creazione":"Data di creazione",
        "email":"Email",
        "amministratore":"Amministratore",
        "telefono":"Telefono",
        "torna_a_gestione_comunita":"Torna a gestione comunità",
        "edifici_inseriti":"Edifici inseriti",
        "note":"Note",
        "aree_individuate":"Aree individuate",
        "aggiungi_area":"Aggiungi area",
        "area":"Area",
        "lunghezza":"Lunghezza",
        "larghezza":"Larghezza",
        "azimut":"Azimut",
        "benvenuto":"Benvenuto",
        "comunita_energetiche":"Comunità energetiche",
        "delibera_in_assemblea_carica_doc":"Delibera in assemblea e carica i documenti richiesti",
        "condomini_coperture_interessate":"Condomini le cui coperture sono interessate",
        "nuovi_impianti":"Nuovi impianti",
        "numero_impianti":"Numero impianti",
        "investimento_stimato":"Investimento stimato",
        "scarica_materiale_informativo_assemblea":"Scarica materiale informativo da portare in assemblea",
        "scarica_incarico_progettazione_esecutiva":"Scarica incarico progettazione esecutiva",
        "carica_incarico_progettazione_esecutiva_firmato":"Carica incarico progettazione esecutiva firmato",
        "carica_delibera_incarico":"Carica delibera incarico",
        "scegli_file":"Scegli file",
        "procedi_con_la_costituzione":"Procedi con la costituzione",
        "documenti_da_scaricare":"Documenti da scaricare",
        "carica_i_seguenti_documenti_firmati":"Carica i seguenti documenti firmati",
        "carica_documenti":"Carica documenti",
        "carica_statuto_firmato":"Carica statuto firmato",
        "scarica_statuto_comunita":"Scarica Statuto comunità",
        "residenziale":"Residenziale",
        "ufficio":"Ufficio",
        "altre_attivita":"Negozio/Artigiano/Ristorante",
        "dati_identificativi":"Dati identificativi",
        "saving_survey_ok":"Sondaggio inviato con successo",
        "error_saving_survey":"Errore nel salvataggio del sondaggio",
        "sondaggio_non_trovato":"Sondaggio non trovato o scaduto",
        "sondaggio_completato":"Hai finito! Grazie per aver compilato il sondaggio.",
        "amministratore_manutentore_nome":"Nome dell'amminstratore o manutentore",
        "not_available":"Non disponibile",
        "risultati_non_ancora_disponibili":"Risultati non ancora disponibili",
        "non_ci_sono_edifici":"Non ci sono edifici",
        "seleziona_tutti":"Seleziona tutti",
        "edifici_considerati":"Edifici considerati",
        "importo_iva_22inclusa":"Importo (iva 22% inclusa):",
        "edificio": "Edificio",
        "riepilogo_edifici_ddot":"Riepilogo edifici:",
        "riepilogo_edifici":"Riepilogo edifici",
        "pagamento_e_durata":"Pagamento e durata",
        "carta_addebito_ddot":"Carta di addebito:",
        "durata_12_mesi_ddot":"Durata 12 mesi:",
        "iban_addebito_ddot":"IBAN addebito: ",
        "periodo_addebito_12_mesi":"Periodo di addebito 12 mesi:",
        "validita_12_mesi":"Validità 12 mesi:",
        "modifica_coperture":"Modifica coperture",
        "elimina":"Elimina",
        "rigenera_risultati":"Rigenera risultati",
        "e_stata_avviata_la_generazione_dei_risultati":"E' stata avviata con successo la rigenerazione dei risultati",
        "problemi_avvio_rigenerazione":"Problemi nella richiesta di rigenerazione dei risultati",
        "error_cancel_community":"Errore nella cancellazione della comunità",
        "community_cancellata_con_successo":"Community cancellata con successo",
        "sei_sicuro_di_voler_cancellare_la_comunita":"Sei sicuro di voler cancella la comunità: ",
        "sei_sicuro_di_voler_sospendere_abbonamento_edificio":"Sei sicuro di voler sospendere l'abbonamento per l'edificio:",
        "community_aggiornata":"Comunità aggiornata con successo",
        "error_update_community":"Errore aggiornamento comunità",
        "scarica_json_risultati":"Scarica json risultati",
        "nome":"Nome",
        "stato_attuale":"Stato attuale",
        "privacy":"Privacy",
        "coperture":"Coperture",
        "sondaggio":"Sondaggio",
        "turno_unico":"Turno unico",
        "onboarding_problem_creation_user":"Problemi nella creazione dell'utente, contatta il supporto",
        "payment_method_impostato":"Metodo di pagamento non impostato, non puoi procedere",
        "inserisci_la_carta_di_credito":"Inserisci la carta di credito",
        "pagamento_non_riuscito_contatta_assistenza":"Pagamento non riuscito: riprova nei prossimi giorni o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "error_aggiornamento_metodo_di_pagamento":"Problemi nell'aggiornamento del pagamento, riprova o contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "inserisci_identificativo_fatture":"Inserisci il codice identificativo per l'invio delle fatture(SDI o pec)",
        "identificativo_fatture":"Identificativo delle fatture(SDI o PEC)",
        "modifica_dati":"Modifica dati",
        "inserisci_dati_cc_contractor":"Inserisci i dati della carta di credito/debito con cui potrai effettuare pagamenti per gli edifici",
        "dati_profilo":"Profilo",
        "i_tuoi_dati_profilo":"I tuoi dati del profilo",
        "modifica":"Modifica",
        "error_updating_profile":"Errore nell'aggiornamento del profilo",
        "problemi_metodo_di_pagamento_edificio":"Il tuo edificio risulta già registrato, ma non riusciamo a recuperare il metodo di pagamento precedentemente impostato, contatta l'assistenza tecnica: backoffice@catastoenergetico.org",
        "inserimento_dati_coperture":"Inserimento dati coperture",
        "non_risultano_edifici_coperture":"Non risultano edifici che necessitano di inserimento delle coperture, verifica che siano stati compilati i dati",
        "in_attesa_di_conferma":"In attesa di conferma",
        "offerta_da":"Offerto da:",
        "indirizzo_edificio":"Indirizzo edificio:",
        "emailSoggettoTerzo":"Email del soggetto terzo",
        "nomeSoggettoTerzo":"Nome del soggetto terzo",
        "dati_importazione_massiva":"Dati per importazione massiva edifici/amministratori",
        "dati_importazione_massiva_subtitle":"In questa sezione potrai caricare un file csv che importerà in automatico gli edifici e gli amministratori, associandoli se necessario",
        "requiredNomeSoggettoTerzo":"Il nome del soggetto terzo è obbligatorio",
        "requiredEmailSoggettoTerzo":"La mail del soggetto terzo è obbligatoria",
        "required_csv":"Il csv è obbligatorio, verifica di averlo caricato correttamente o riprova",
        "importazione_completata":"Importazione completata.",
        "tipo_di_importazione":"Tipo di importazione",
        "simple":"Semplificata",
        "terzo":"Terzo",
        "scarica_qrcode":"Scarica Qr-Code",
        "copyright_footer":"© 2011-2024 Catasto Energetico. All Rights Reserved.",
        "vedi_risposte":"Vedi chi ha risposto"
    }
  }
};
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it',
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false
    }
  });
