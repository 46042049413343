import {ApiUtil} from "../apiUtils";
import {User} from "../../model/types/user";
import {PaymentMethodDTO} from "../../model/dto/payment.dto";
import {UserUpdatePaymentMethodPayload} from "../../model/payload/payment.payload";
import {ProfileCE} from "../../model/types/profilece";

export class UserController {
    public static baseName = "user"

    public static async profile(): Promise<User> {
        return await ApiUtil.makeRequest<User>(
            "get",
            UserController.baseName,
            true,
            null,
        );
    }
    //Only for backoffice
    public static async userAll(): Promise<Array<ProfileCE>> {
        return await ApiUtil.makeRequest<Array<ProfileCE>>(
            "get",
            UserController.baseName+"/all",
            true,
            null,
        );
    }
    public static async paymentMethod(): Promise<PaymentMethodDTO> {
        const resp = await ApiUtil.makeRequest<any>(
            "get",
            UserController.baseName+"/paymentMethod",
            true,
            null,
        );
        if(!resp.result && resp.error){
            console.log("Ritorno null")
            return null
        }
        return resp as PaymentMethodDTO
    }
    public static async paymentSetupIntent(): Promise<{clientSecret: string}> {
        return await ApiUtil.makeRequest<{clientSecret: string}>(
            "get",
            UserController.baseName+"/payment/setupIntent",
            true,
            null,
        );
    }
    public static async updateUser(user: User): Promise<User> {
        return await ApiUtil.makeRequest<User>(
            "post",
            UserController.baseName+"/update",
            true,
            user,
        );
    }
    public static async updatePaymentMethod(userUpdatePaymentMethod: UserUpdatePaymentMethodPayload): Promise<PaymentMethodDTO> {
        return await ApiUtil.makeRequest<PaymentMethodDTO>(
            "post",
            UserController.baseName+"/updatePaymentMethod",
            true,
            userUpdatePaymentMethod,
        );
    }
    //BACKOFFICE ONLY
    public static async findUserByBackOffice(idUser: number): Promise<User> {
        return await ApiUtil.makeRequest<User>(
            "get",
            UserController.baseName+`/find/${idUser}`,
            true,
            null,
        );
    }
}
