import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import type { SxProps } from '@material-ui/system';
import logo from '../res/img/catasto_energetico_icona.png';

interface LogoProps {
  sx?: SxProps<Theme>;
}
const LogoFooter: FC<LogoProps> = (props) => (
  <div>
    <img src={logo} width={"70"} height="70"/>
  </div>
);

export default LogoFooter;
