import type {FC} from 'react';
import React, {useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import type {Theme} from '@material-ui/core';
import {Box, Drawer, Link} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Logo from './Logo';
import NavSection from "./NavSection";
import {
    sectionsAppLogged,
    sectionsAppLoggedManutentore,
    sectionsAppLoggedBackOffice,
    sectionsAppUnLogged, DEFAULT_WEBSITE
} from "../constants";
import useAuth from "../hooks/useAuth";
import {Role} from "../model/types/profilece";

interface MainSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const MainSidebar: FC<MainSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const {user} = useAuth()

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={!lgUp && openMobile}
      variant="temporary"
      PaperProps={{
        sx: {
          backgroundColor: 'background.default',
          width: 256
        }
      }}
    >
      <Box
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 2
        }}
      >
          <a href={DEFAULT_WEBSITE}>
          <Logo />
          </a>
          <Box sx={{ p: 2 }}>
              {  user  && user.role == Role.amministratore? (sectionsAppLogged.map((section) =>  (
                  <NavSection
                      key={section.title}
                      pathname={location.pathname}
                      sx={{
                          '& + &': {
                              mt: 3
                          }
                      }}
                      horizontal={false}
                      {...section}
                  />
              ))): user  && user.role == Role.manutentore? (sectionsAppLoggedManutentore.map((section) =>  (
                  <NavSection
                      key={section.title}
                      pathname={location.pathname}
                      sx={{
                          '& + &': {
                              mt: 3
                          }
                      }}
                      horizontal={false}
                      {...section}
                  />
              ))): user  && user.role == Role.backoffice? (sectionsAppLoggedBackOffice.map((section) =>  (
                  <NavSection
                      key={section.title}
                      pathname={location.pathname}
                      sx={{
                          '& + &': {
                              mt: 3
                          }
                      }}
                      horizontal={false}
                      {...section}
                  />
              )))   : (sectionsAppUnLogged.map((section) =>  (
                  <NavSection
                      key={section.title}
                      pathname={location.pathname}
                      sx={{
                          '& + &': {
                              mt: 3
                          }
                      }}
                      horizontal={false}
                      {...section}
                  />
              )))}
          </Box>

      </Box>
    </Drawer>
  );
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default MainSidebar;
