import i18n from "i18next";


export const IVA = 0.22

export const urlCatasto= "http://catastoenergetico.org"
export const urlAddEdificio = urlCatasto+"/edit/gestioneedificio.aspx?Skeda=NUOVA&IDUNI=mve0ht2usgo1exm3sjdmwkau8997&MDId=4101"
export const refBase64IdCommunitySurvey = "community-5MiaiwdAPUHOtzTqcE5S"
export const THEMES = {
  LIGHT: 'LIGHT',
};
export const DEFAULT_WEBSITE = "http://catastoenergetico.org"
export const LINK_PRIVACY = "https://www.iubenda.com/privacy-policy/87466855"
export const LINK_COOKIE_POLICY = "https://www.iubenda.com/privacy-policy/87466855/cookie-policy"

export const sectionsAppLogged = [
  {
    title: "",
    items: [
      {
        title: i18n.t("gestioni_sottoscrizioni"),
        path: '/mybuildings',
      },
      {
        title: i18n.t("sviluppo_gestione_comunita"),
        children:[
          {
            title: i18n.t("le_tue_comunita"),
            path: '/mycommunities',
          },

          {
            title: i18n.t("crea_nuova_comunita"),
            path: '/newcommunity',
          },
        ],
        path: '#'
      }
    ]
  },
];

export const sectionsAppUnLogged = [
  {
    title: "",
    items: [

    ]
  }
];
export const sectionsAppLoggedManutentore = [
  {
    title: "",
    items: [
      {
        title: i18n.t("gestioni_sottoscrizioni"),
        path: '/mybuildings',
      },
    ]
  }
];

export const sectionsAppLoggedBackOffice = [
  {
    title: "",
    items: [
      {
        title: i18n.t("gestione_comunita"),
        path: '/bo/communities',
      },
      {
        title: i18n.t("pagamenti"),
        path: '/bo/buildings',
      },
    ]
  }
];
/*
BACKUP
 {
        title: i18n.t("my_home"),
        path: '/',
      },
 */