import { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { THEMES } from '../constants';
import {BuildingCE} from "../model/types/buildingce";

export class NewCommunity {
  constructor(buildings: Array<BuildingCE>) {
    this.buildings = buildings;
  }

  buildings?: Array<BuildingCE>;
}

export interface AppContextValue {
  newCommunity: NewCommunity;
  saveNewCommunity: (update: NewCommunity) => void;
}

const initialSettings: NewCommunity = {
  buildings: null
};

export const restoreCommunity = (): NewCommunity | null => {
  let settings = null;

  try {
    const storedData: string | null = window.localStorage.getItem('newCommunity');

    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = {
        buildings: Array<BuildingCE>()
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeNewCommunity = (newCommunity: NewCommunity): void => {
  window.localStorage.setItem('newCommunity', JSON.stringify(newCommunity));
};

const AppContext = createContext<AppContextValue>({
  newCommunity: initialSettings,
  saveNewCommunity: () => {}
});

export const AppProvider: FC = (props) => {
  const { children } = props;
  const [newCommunity, setNewCommunity] = useState<NewCommunity>(initialSettings);

  useEffect(() => {
    const restoredSettings = restoreCommunity();

    if (restoredSettings) {
      setNewCommunity(restoredSettings);
    }
  }, []);

  const saveNewCommunity = (updatedNewCommunity: NewCommunity): void => {
    setNewCommunity(updatedNewCommunity);
    storeNewCommunity(updatedNewCommunity);
  };

  return (
    <AppContext.Provider
      value={{
        newCommunity,
        saveNewCommunity
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AppConsumer = AppContext.Consumer;

export default AppContext;
