import {ApiUtil} from "../apiUtils";
import {GetProfileCEPayload} from "../../model/payload/profile.payload";
import {ProfileCE} from "../../model/types/profilece";
import {User} from "../../model/types/user";
import {AuthFromEmailOnBoardingResponseDTO, AuthResponseDTO} from "../../model/dto/auth.dto";

export class AuthController {
    public static baseName = "auth"
    public static async sign(username: string, password: string): Promise<AuthResponseDTO> {
        return await ApiUtil.makeRequest<AuthResponseDTO>(
            "post",
            AuthController.baseName+"/signin",
            false,
            {username: username, password: password},
        );
    }

    public static async auth(token: string): Promise<AuthResponseDTO> {
        return await ApiUtil.makeRequest<AuthResponseDTO>(
            "post",
            AuthController.baseName+"/tkn",
            false,
            {ceToken: token},
        );
    }

    public static async authFromEmailOnBoarding(token: string,onboarding: any): Promise<AuthFromEmailOnBoardingResponseDTO> {
        return await ApiUtil.makeRequest<AuthFromEmailOnBoardingResponseDTO>(
            "post",
            AuthController.baseName+"/fromEmailOnBoarding",
            false,
            {tkn: token,onboarding: onboarding},
        );
    }
    public static async passwordRecovery(username: string): Promise<{}> {
        return await ApiUtil.makeRequest<{}>(
            "post",
            AuthController.baseName+"/recoveryPassword",
            false,
            {username: username},
        );
    }
}
