import {ProfileCE, Role} from "./profilece";

export class User {
    id: number
    idUserCE: string
    role: Role
    idStripe: string
    invoiceIdentifier: string
    dataCE: ProfileCE
    public static displayRole(user: User){
        switch (user.role){
            case Role.amministratore:
                return "Amministratore"
            case Role.manutentore:
                return "Manutentore"
            case Role.backoffice:
                return "Backoffice"
        }
        return null;
    }
    public static displayName(user: User){
        return user.dataCE.name+" "+user.dataCE.lastname
    }
}
