import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import Footer from './Footer';
import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';

interface MainLayoutProps {
    children?: ReactNode;
}

const MainLayoutRoot = experimentalStyled('div')(
    ({ theme }) => (
        {
            paddingTop: 64,
            backgroundColor: theme.palette.background.paper,
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1
        }
    )
);

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
    const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);

    return (
        <MainLayoutRoot>
            <MainNavbar
                onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
            />
            <MainSidebar
                onMobileClose={(): void => setIsSidebarMobileOpen(false)}
                openMobile={isSidebarMobileOpen}
            />
            <div style={{flexGrow: 1}}>
                {children || <Outlet />}
            </div>
            <Footer />
        </MainLayoutRoot>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node
};

export default MainLayout;
