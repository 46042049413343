import type {FC} from 'react';
import React from "react";
import PropTypes from 'prop-types';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {AppBar, Box, Button, Divider, IconButton, Toolbar, Typography} from '@material-ui/core';
import MenuIcon from '../res/icons/Menu';
import Logo from './Logo';
import {
    sectionsAppLogged,
    sectionsAppLoggedManutentore,
    sectionsAppLoggedBackOffice,
    sectionsAppUnLogged, DEFAULT_WEBSITE
} from "../constants";
import NavSection from "./NavSection";
import useAuth from "../hooks/useAuth";
import {User} from 'src/model/types/user';
import i18n from "i18next";
import {Role} from "../model/types/profilece";
import AccountPopover from "./AccountPopover";

interface MainNavbarProps {
  onSidebarMobileOpen?: () => void;
}

const MainNavbar: FC<MainNavbarProps> = (props) => {
  const { onSidebarMobileOpen } = props;
    const location = useLocation();
    const {user} = useAuth()

    let section = sectionsAppUnLogged
    if(user  && user.role == Role.amministratore){
        section = sectionsAppLogged
    }else if(user  && user.role == Role.manutentore){
        section = sectionsAppLoggedManutentore
    }else if(user  && user.role == Role.backoffice){
        section = sectionsAppLoggedBackOffice
    }
    return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary'
      }}
    >

      <Toolbar sx={{minHeight: 64 }}>
          {
              section && section.length > 0  && section[0].items.length > 0? (
                  <>
                      <IconButton
                          color="inherit"
                          onClick={onSidebarMobileOpen}
                          sx={{
                              display: {
                                  md: 'none'
                              }
                          }}
                      >
                          <MenuIcon fontSize="small" />
                      </IconButton></>
              ) : null
          }
          <Box sx={{flexGrow: 1}}>
          <a href={DEFAULT_WEBSITE}>
              <Logo
            sx={{
              display: {
                md: 'inline',
                xs: 'none'
              }
            }}
          />
        </a>
          </Box>
              <Box sx={{ml: 1,mr: 1,flexGrow: 1,display: {
                      md: 'inline',
                      xs: 'none',
                  }, alignItems:'center', justifyContent: "center"}} >
                  {
                      (section.map((section) =>  (
                          <NavSection
                              key={section.title}
                              pathname={location.pathname}
                              sx={{
                                  '& + &': {
                                      mt: 3
                                  }
                              }}
                              horizontal={true}
                              {...section}
                          />
                      )))
                  }
          </Box>
          <Box sx={{ flexGrow: 1,display: {
                  md: 'none',
                  xs: 'inline'
              }, }} ></Box>
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'flex'
            }
          }}
        >
            {
                user ? (
                    <Box sx={{ ml: 2 }}>
                        <AccountPopover />
                    </Box>
                ): null /* (
                    <Box sx={{ml: 2}}>
                        <Button variant="contained" href="/login" size="medium" color="secondary">
                            {i18n.t("accedi")}
                        </Button>
                    </Box>
                )*/
            }
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;
